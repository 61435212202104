/* eslint @next/next/inline-script-id: 0 */
import {
    Text,
    TextVariants,
    Image,
    ButtonSize,
    ButtonVariant,
    Link,
    RawHtmlProps,
    Button,
} from '$shared/components';
import Script from 'next/script';
import parse, {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import React, { useMemo } from 'react';
import { StyledTable } from '../styled';
import { LinkPageOverlay } from '../components/LinkPageOverlay';
import { GeneratedThemeShade, useThemeShade } from '~/theme';
import { ConsentLink } from '../../CookieInformation/Components/ConsentLink';
import { ConditionalWrap } from '~/shared/utils/jsx';
import { StrokeTextMode } from '../../StrokeText';

type RegularTextTags = typeof regularTextTags[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

type HeadingTags = typeof headingTags[number];
const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
const rteButtonClasses = [
    'button-primary-lg',
    'button-primary-sm',
    'button-secondary-lg',
    'button-secondary-sm',
];

const options = (
    moduleShade: GeneratedThemeShade,
    linkStrokeMode?: StrokeTextMode
): HTMLReactParserOptions => ({
    /**
     * Required to prevent warning about whitespace in tables - if tables are used.
     * Might removed intended whitespace.
     *
     * @see https://github.com/remarkablemark/html-react-parser#trim
     */
    trim: true,

    /**
     * Replace HTML tags with react components
     * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
     */
    replace: (domNode) => {
        const { attribs, children, name = '', parent } = domNode as Element;
        const props = attributesToProps(attribs || {});
        const nestedChildren = children
            ? domToReact(children, options(moduleShade, linkStrokeMode))
            : '';

        if (!attribs) {
            return;
        }

        if (regularTextTags.includes(name as RegularTextTags)) {
            const tag = name as RegularTextTags;
            return (
                <Text {...props} as={tag} shade={moduleShade.textShade} children={nestedChildren} />
            );
        }

        if (headingTags.includes(name as HeadingTags)) {
            const size = Number(name.replace(/\D/g, ''));

            return (
                <Text
                    {...props}
                    shade={moduleShade.headlineShade}
                    as={name as HeadingTags}
                    variant={`display${size}` as TextVariants}
                    children={nestedChildren}
                />
            );
        }

        if (attribs?.class === 'cookie-consent-trigger') {
            return <ConsentLink children={nestedChildren} shade={moduleShade.textShade} />;
        }

        if (name === 'span' && attribs?.class) {
            const firstClass = attribs.class.split(' ')[0];
            if (rteButtonClasses.includes(firstClass)) {
                const [_, variant, size] = attribs.class.split('-');
                return (
                    <Button
                        variant={variant as ButtonVariant}
                        size={size as ButtonSize}
                        shade={moduleShade.buttonShade}
                        className={attribs.class}
                        id={attribs.id}
                    >
                        <span className={attribs.class} id={attribs.id}>
                            {nestedChildren}
                        </span>
                    </Button>
                );
            }
        }

        if (name === 'a') {
            const parentIsButton = rteButtonClasses.includes((parent as Element)?.attribs?.class);
            const childIsButton = rteButtonClasses.includes(
                (children[0] as Element)?.attribs?.class
            );
            if (parentIsButton || childIsButton) {
                const buttonElement = (parentIsButton ? parent : children[0]) as Element;
                const [_, variant, size] = buttonElement.attribs.class.split('-');
                const { href, target, title } = attribs;

                return (
                    <ConditionalWrap
                        if={attribs.target === 'dialog'}
                        wrap={(link) => (
                            <LinkPageOverlay
                                url={href}
                                headline={title}
                                variant="page"
                                children={link}
                            />
                        )}
                    >
                        <Link
                            target={target}
                            title={title}
                            href={href}
                            variant={variant as ButtonVariant}
                            size={size as ButtonSize}
                            shade={moduleShade.buttonShade}
                            children={nestedChildren}
                            type={attribs.target === 'dialog' ? 'raw' : 'router'}
                        />
                    </ConditionalWrap>
                );
            }

            if (attribs.target === 'dialog') {
                return (
                    <LinkPageOverlay url={props.href} headline={attribs.title} variant="page">
                        <Link
                            href={props.href}
                            strokeMode={linkStrokeMode}
                            type="raw"
                            textShade={moduleShade.textShade}
                        >
                            {nestedChildren}
                        </Link>
                    </LinkPageOverlay>
                );
            }

            return (
                <Link href={props.href} {...props} type="plain">
                    {nestedChildren}
                </Link>
            );
        }

        if (name === 'img') {
            const API_URL = process.env.NEXT_PUBLIC_UMBRACO_URL;
            const isRelative = props.src.startsWith('/');
            const src = isRelative ? `${API_URL}${props.src}` : props.src;

            return (
                <Image
                    src={src}
                    alt={props.alt}
                    width={Number(props.width)}
                    height={Number(props.height)}
                    layout="responsive"
                />
            );
        }

        if (name === 'table') {
            return <StyledTable {...props} children={nestedChildren} />;
        }

        if (name === 'script') {
            return <Script {...props} children={nestedChildren} />;
        }
    },
});

const interpolate = (html: string, interpolation: Pick<RawHtmlProps, 'interpolation'> = {}) => {
    let interpolatedHtml = html;
    const entries = Object.entries(interpolation);
    const interpolationPattern = / {{.*}}/;

    entries.forEach(([key, value]) => {
        if (value) {
            interpolatedHtml = interpolatedHtml?.replace(
                new RegExp(`{{${key}}}`, 'g'),
                String(value)
            );
        }
    });

    // Remove any interpolation that doesn't have a value, including a space
    return interpolatedHtml?.replace(new RegExp(interpolationPattern, 'g'), '') || '';
};

export const useRawHtml = ({
    html = '',
    backgroundColor = 'light',
    linkStrokeMode = 'once',
    interpolation,
}: RawHtmlProps) => {
    const moduleShade = useThemeShade({ backgroundColor });
    return useMemo(() => {
        const interpolatedHtml = interpolate(html, interpolation) || '';
        const markup = parse(interpolatedHtml, options(moduleShade, linkStrokeMode));
        return <>{markup}</>;
    }, [html, moduleShade, linkStrokeMode, interpolation]);
};
