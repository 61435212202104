import { Theme } from '@emotion/react';
import { Variants as FramerVariants } from 'framer-motion';
import { Variants } from './Overlay';

type AnimationVariants = Partial<Record<Variants, FramerVariants>>;

export const animationVariants = (variant: Variants, theme: Theme): FramerVariants | undefined => {
    const { animations } = theme;
    const { springEntrance, springExit, defaultTranslateY } = animations;

    const variants: AnimationVariants = {
        minimal: {
            initial: {
                transform: `translateX(-50%) translateY(calc(-50% + ${defaultTranslateY}))`,
                opacity: 0,
            },
            exit: {
                display: 'none',
                transition: {
                    duration: 0,
                },
            },
            animate: {
                transform: 'translateX(-50%) translateY(calc(-50% + 0px))', // We need the px calc to make Framer motion work, with from and to state.
                opacity: 1,
                transition: {
                    type: 'spring',
                    ...springEntrance,
                },
            },
        },
        slide: {
            initial: {
                transform: 'translate3d(100%, 0, 0)',
            },
            exit: {
                opacity: 0,
                transition: {
                    type: 'spring',
                    ...springExit,
                },
            },
            animate: {
                transform: 'translate3d(0%, 0, 0)',
                transition: {
                    type: 'spring',
                    ...springEntrance,
                    mass: 0.85, // Reduces bounce effect
                },
            },
        },
        full: {
            initial: {
                transform: `translate3d(0, 100%, 0)`,
            },
            exit: {
                display: 'none',
                transition: {
                    duration: 0,
                },
            },
            animate: {
                transform: 'translate3d(0, 0%, 0)',
                opacity: 1,
                transition: {
                    type: 'spring',
                    ...springEntrance,
                    mass: 0.8, // Reduces bounce effect
                },
            },
        },
        page: {
            initial: {
                opacity: 0,
            },
            exit: {
                display: 'none',
            },
            animate: {
                opacity: 1,
                transition: {
                    type: 'spring',
                    ...springEntrance,
                    mass: 0.8, // Reduces bounce effect
                },
            },
        },
    };

    return variants[variant];
};

/**
 * Usefull for user feedback, on missing interactive elements
 */
export const shakeAnimation = {
    transform: [
        'translate3d(0px, 0, 0)',
        'translate3d(-1px, 0, 0)',
        'translate3d(2px, 0, 0)',
        'translate3d(-4px, 0, 0)',
        'translate3d(4px, 0, 0)',
        'translate3d(-4px, 0, 0)',
        'translate3d(4px, 0, 0)',
    ],
};
